<template>
	<div class="tips-box">
		<div class="tips">
			<img src="https://exh.szcreativeweek.com/static/img/noLog.c1ee1b5.png" alt="" class="img">
			<span>页面不存在 <br> Page does not exist</span>
		</div>
	</div>
</template>

<script>
  export default {
    name: '',
    data() {
      return {
				name: ''
      }
    },
    created() {
			this.name = this.$route.path
    },
    methods: {

    },
  }
</script>

<style lang="less" scoped>
	.tips-box {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 80vh;
		
		.tips {
			position: relative;
			width: 80%;
			padding-bottom: 30px;
			
			.img {
				width: 100%;
			}
			
			span {
				position: absolute;
				bottom: 0;
				display: block;
				width: 100%;
				text-align: center;
				font-size: 20px;
				color: #999;
				line-height: 36px;
			}
		}
		
	}

</style>
